import { useState, useEffect, useRef } from 'react';
import { throttle } from 'lodash';
import './App.css';
import FuturisticBorder from './components/FuturisticBorder';
import FutureButton from './components/FutureButton';

function App() {
  const [videoSrc, setVideoSrc] = useState(getVideoSource());
  const videoRef = useRef(null);  // Create a ref for the video element

  useEffect(() => {
    const handleResize = throttle(() => {
      const newVideoSrc = getVideoSource();
      if (newVideoSrc !== videoSrc) {
        setVideoSrc(newVideoSrc);
        videoRef.current?.load();  // Force video to reload
        console.log(`Orientation changed to: ${newVideoSrc.includes('Vertical') ? 'portrait' : 'landscape'}`);
      }
    }, 200); // Fires at most once every 200 milliseconds

    window.addEventListener('resize', handleResize);
    return () => {
      handleResize.cancel(); // Cancel the throttle timer on cleanup
      window.removeEventListener('resize', handleResize);
    };
  }, [videoSrc]); // Add videoSrc to dependency array to track changes

  function getVideoSource() {
    return window.innerWidth > window.innerHeight
      ? 'https://www.theartistic.engineer/BGHorizontal.mp4'
      : 'https://www.theartistic.engineer/BGVertical.mp4';
  }

  return (
    <div className="App">
      <FutureButton text="Artistic" onClick={() => alert("Button Clicked!")}/>
      <video ref={videoRef} autoPlay loop muted playsInline className="background-video">
        <source src={videoSrc} type="video/mp4" />
      </video>
      <FuturisticBorder borderColor="cyan" backgroundColor="#252C37" />
    </div>
  );
}

export default App;
