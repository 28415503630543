import React, { useEffect, useState } from 'react';
import './FuturisticBorder.css';

function FuturisticBorder({ borderColor = 'cyan', backgroundColor = '#252C37' }) {
  const [isReady, setIsReady] = useState(false);
  const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);

  useEffect(() => {
    // Check for orientation change
    const handleOrientationChange = (e) => {
      setIsLandscape(e.matches);
    };

    const mql = window.matchMedia("(orientation: landscape)");
    mql.addListener(handleOrientationChange);

    // Set a timeout to add the 'ready' class
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 1000); // Adjust this delay as needed

    return () => {
      clearTimeout(timer);
      mql.removeListener(handleOrientationChange);
    };
  }, []);

  // Only render the component in landscape mode
  if (!isLandscape) {
    return null;
  }

  return (
    <>
      <header className={isReady ? 'ready' : ''}>
        <div className="box"></div>
        <div className="curve">
          <div className="left"><div></div></div>
          <div className="center"></div>
          <div className="right"><div></div></div>
        </div>
      </header>
      <footer className={isReady ? 'ready' : ''}>
        <div className="curve">
          <div className="left"><div></div></div>
          <div className="center"></div>
          <div className="right">
            <div>
            </div>
          </div>
        </div>
        <div className="box"></div>
      </footer>
    </>
  );
}

export default FuturisticBorder;
