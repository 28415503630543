import React, { useState } from 'react';
import './futureButton.css';

const FutureButton = ({ text, onClick }) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  return (
    <button
      className={`btn-2 ${hover ? 'hover' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}  // Add onClick event handler
    >
      {text}
    </button>
  );
}

export default FutureButton;
